import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Inspirationsföreläsningar och samtal</h1>
      <span className="ingress">
        Rörlighet i staten erbjuder inspirationsföreläsningar och digitala
        erfarenhetsutbyte, så kallade popup-samtal för alla medarbetare i
        myndigheter som är medlemmar i Rörlighet i staten.
      </span>
      <br />
      <br />

      <p>&nbsp;</p>
      <h2>Inspirationsföreläsningar</h2>
      <br />
      <h5>Boka in höstens inspirationsföreläsningar med Rörlighet i staten</h5>
      <p>
        <strong>
          I höst bjuder Rörlighet i staten in till tre digitala föreläsningar
          för att stärka kompetensen inom viktiga samhälls- och
          arbetslivsområden. Experter bjuder på insiktsfulla föreläsningar om
          digitalisering med AI, frågan om ålderism och ledarskap för en hållbar
          hybrid arbetsplats. Föreläsningarna är för dig som arbetar på en
          myndighet inom Rörlighet i staten.
        </strong>
      </p>
      <p>
        Boka gärna in tiderna i kalendern redan nu. Anmälan kommer ut efter
        sommaren.
      </p>
      <br />
      <p>
        <strong>Digitalisering med AI i offentlig förvaltning</strong>
      </p>
      <p>
        Den 20 september 9.00 – 10.00 kommer Mats Snäll från
        Digitaliseringsmyndigheten att tala om hur man kan digitalisera
        offentlig förvaltning med hjälp av AI. Deltagarna kommer få en inblick i
        hur AI kan förbättra effektiviteten och kvaliteten på offentliga
        tjänster.
      </p>
      <p>
        Datum: 20 september
        <br />
        Tid: 09:00-10:00
        <br />
        Föreläsare: Mats Snäll från Digitaliseringsmyndigheten
      </p>
      <br />
      <br />
      <p>
        <strong>Ålderism</strong>
      </p>
      <p>
        Den 18 oktober 09:00-10:00 kommer John Mellkvist från Kreab adressera
        frågan om ålderism i arbetslivet. Föreläsningen syftar till att öka
        medvetenheten om åldersdiskriminering och främja en inkluderande
        arbetsmiljö där alla åldersgrupper värderas lika.
      </p>
      <p>
        Datum: 18 oktober
        <br />
        Tid: 09:00-10:00
        <br />
        Föreläsare: John Mellkvist från Kreab
      </p>
      <br />
      <br />
      <p>
        <strong>
          Självledarskap och kollektivt ledarskap för en hållbar hybrid
          arbetsplats
        </strong>
      </p>
      <p>
        Den 22 november kommer Nina Bozic från RISE utforska strategier för både
        självledarskap och kollektivt ledarskap, med fokus på att skapa hållbara
        hybrida arbetsplatser. Föreläsningen kommer att ge värdefulla verktyg
        för att navigera och leda i en flexibel arbetsmiljö.
      </p>
      <p>
        Datum: 22 november
        <br />
        Tid: 09:00-10:00
        <br />
        Föreläsare: Nina Bozic, forskare på RISE
      </p>
      <p>
        <span>
          <br />
        </span>
        <br />
        <span>
          Har du frågor om föreläsningarna kontakta:
          <a href="mailto:cecilia.carenfelt@shm.se">
            {" "}
            Cecilia.Carenfelt@shm.se
          </a>{" "}
          eller{" "}
          <a href="mailto:par.jenestam@energimyndigheten.se">
            {" "}
            par.jenestam@energimyndigheten.se
          </a>
        </span>
      </p>
      <p>&nbsp;</p>

      <h2>Digitalt erfarenhetsutbyte - popup-samtal</h2>
      <p>
        <span>
          Rörlighet i statens popup-samtal är digitala erfarenhetsutbyten kring
          en aktuell fråga. Samtalet leds av en facilitator och inleds med en
          introduktion av temat, därefter läggs största tiden på utbyte mellan
          deltagarna. Målgruppen är medarbetare inom myndigheter i Rörlighet i
          staten.
          <br />
        </span>
        <br />
        <br />
        <h4>Erfarenhetsutbyte om desinformation – Rörlighet i staten</h4>
        <span>
          Dokumentation Popup-samtal om desinformation
          <br />
          31 maj kl 9-11
        </span>
        <br />
        <Link to="/inspirationsforelasningar-och-samtal/erfarenhetsutbyte-om-desinformation/">
          Läs mer
        </Link>
      </p>
      <p>&nbsp;</p>
      <h4>Har du tips på ämnen?</h4>
      <p>
        <span></span>
        <span>
          Har du förslag till ett aktuellt ämne som vi kan ta upp vid nästa
          popup-samtal? Skicka förslaget till{" "}
          <a href="mailto:christina.frimodig@naturvardsverket.se ">
            Christina Frimodig
          </a>
          <br />
        </span>
      </p>
      <p>&nbsp;</p>

      <h2>Kontakt</h2>
      <p>
        <span>
          Har du frågor är du välkommen att höra av dig till Christina Frimodig,
          förnamn.efternamn@naturvardsverket.se
          <br />
        </span>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
