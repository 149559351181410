export const moreNews = [
  {
    Nyhet: {
      createDate: "2023-12-14T16:04:56",
      title: "Anmälan till ledarskapsutveckling för chefer är öppen",
      bodyText: {
        content:
          '<p>Arbetar du som chef och vill utveckla och stärka ditt ledarskap? Då är det här utbildningen för dig. Under tio dagar får du verktyg för hur du genom ditt ledarskap kan skapa engagemang hos dina medarbetare och hur du som chef i staten kan bidra till att göra staten till en bra arbetsgivare.</p>\r\n<p>Programmet startar i april och pågår till november. Utbildningen vänder sig till chefer som vill utveckla och stärka sitt ledarskap.</p>\r\n<p>Är du intresserad av att delta skicka en intresseanmälan till myndighetens representant i Rörlighet i statens programgrupp. <a href="https://rorlighetistaten.se/deltagande-myndigheter">Deltagande myndigheter</a></p>\r\n<p>Vid frågor rörande utbildningen kontakta Carola Olofsson, <a>fornamn.efternamn@naturvardsverket.se</a></p>\r\n<p>Läs mer om utbildningen här:</p>\r\n<p><a href="https://rorlighetistaten.se/program-foer-din-utveckling/ledarskapsutveckling-foer-chefer">Ledarskapsutveckling för chefer</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-08T08:04:56",
      title:
        "Tandvårds- och läkemedelsförmånsverket söker en projektledare för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att arbeta som projektledare för IT-upphandling på Tandvårds- och läkemedelsförmånsverket? De söker nu en projektledare för inlån och sex månader från tillträde.</p><p>Läs mer</p><p><a href="https://rorlighetistaten.se/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-08T08:20:44",
      title: "Post- och telestyrelsen söker en jurist för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att arbeta som jurist på Post- och telestyrelsen? Post och telestyrelsen söker nu en jurist för inlån med start efter nyår utifrån överenskommelse och sex månader från tillträde.</p><p>Läs mer</p><p><a href="https://rorlighetistaten.se/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-12T08:20:44",
      title:
        "Vinnova söker en handläggare för industriell utveckling för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Vinnova? De söker just nu en handläggare för industriell utveckling för inlån under sex månader.</p><p><a href="https://rorlighetistaten.se/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-15T08:20:44",
      title: "Ekonomistyrningsverket blir RIS tjugonde medlem! ",
      bodyText: {
        content:
          '<p><strong>Nu har RIS fått ytterligare en medlem, Ekonomistyrningsverket! Ekonomistyrningsverket, som brukar förkortas ESV, är en expertmyndighet som utvecklar den ekonomiska styrningen för regeringen, Regeringskansliet och andra statliga myndigheter samt gör analyser och prognoser av statens ekonomi. Elina Bentham är HR-specialist på ESV och ser väldigt positivt på RIS-samarbetet och hur det möjliggör fler utvecklingsmöjligheter när det kommer till samverkan och utbyte av erfarenheter.</strong></p><p>-	Vi lever i en föränderlig tid och måste snabbt kunna anpassa oss till nya förutsättningar utifrån givna uppdrag. Att samverka och dela med oss av våra erfarenheter och kompetenser tror vi är en nyckel för att lyckas och utvecklas tillsammans. Därför ser vi framemot möjligheten att bredda detta inom ramen för RIS.</p><p>- Och eftersom att vi på ESV är vana att sedan tidigare verka inom nätverk och samarbeten så är det nu väldigt positivt att få möjligheten att på ett mer strukturerat sätt erbjuda våra medarbetare dessa utvecklingsmöjligheter, säger Elina.</p><p>Vad tycker du att vi som jobbar på myndigheter kan hjälpas åt med för att göra staten till Sveriges bästa arbetsplats?</p><p>-	Som myndigheter står vi ofta inför liknande utmaningar, att kunna hjälpas åt och dela med sig av kompetenser, erfarenheter och perspektiv är något som gör staten unikt och viktigt att värna.</p><p><img src="https://rorlighetistaten.se/media/1501/elina-bentham.jpg" alt="Bild på Elina Bentham"/></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-17T08:20:44",
      title: "Nätverk för statliga produktägare",
      bodyText: {
        content:
          "<p>Jobbar du som produktägare på någon av myndigheterna inom Rörlighet i staten och vill utbyta erfarenheter med andra? Vi startar ett nytt nätverk kring att bygga digitala produkter i en statlig kontext.</p><p>Så här beskriver kontaktpersonen för nätverket Andreas Duvhammar på Tillväxtverket tanken med nätverket.</p><p>Att bygga digitala produkter i en statlig kontext är speciellt jämfört med den privata sfären. Många av produktägares eller product managers klassiska kärnuppgifter blir mindre relevanta (eller till och med helt obsoleta) medan andra frågor blir desto viktigare.</p><p>Exempelvis får affärsmässiga frågor som marknadsanalys, produkt-marknads-matchning, intäkter och marknadsföring mindre relevans. Istället blir frågor som att leverera under en fastställd budget, efterleva regelverk, göra det mesta av skattebetalarnas pengar och skapa maximal samhällsnytta viktiga. Vilka faktorer är viktiga för att lyckas bygga en värdefull digital produkt under de särskilda omständigheterna? Hur mäter vi om vi lyckats när vi pratar om samhällsnytta istället för intäkter?</p><p>Nästan alla resurser och utbildningar som handlar om produktägarskap utgår ifrån att man rör sig inom den privata sektorn. Jag ser därför en blind spot som ett nätverk inom Rörlighet i Staten skulle kunna fylla. Statliga produktägare skulle kunna utbyta erfarenheter, coacha och tipsa varandra. Vi utvecklar gemensamt hur vi vill jobba i nätverket och vilka frågor vi vill ta upp.</p><p>Är du intresserad av att delta i nätverket, kontakta Andreas Duvhammar Andreas.Duvhammar@tillvaxtverket.se</p>",
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-01-17T09:20:44",
      title: "Svenska ESF-rådet söker en HR-specialist för inlån",
      bodyText: {
        content:
          '<p>Vill  du prova på att jobba på Svenska ESF-rådet? De söker just nu en HR-specialist för inlån under fem månader.</p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-02-07T09:20:44",
      title: "Vinnova söker en kommunikatör för inlån",
      bodyText: {
        content:
          '<p>Vill du prova att jobba på Vinnova? De söker just nu en kommunikatör för inlån under sex månader.</p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-03-06T09:20:44",
      title: "Naturvårdsverket söker klimathandläggare för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Naturvårdsverket? De söker nu en handläggare för inlån till sista augusti.</p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-03-07T09:20:44",
      title: "Ekonomistyrningsverket söker en jurist för inlån",
      bodyText: {
        content:
          '<p>Vill du prova att jobba på Ekonomistyrningsverket? De söker just nu en jurist för inlån under sex månader.</p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-03-18T09:20:44",
      title: "Kännedomsundersökning om Rörlighet i staten",
      bodyText: {
        content:
          '<p>I dagarna får alla medarbetare i de 20 myndigheter som ingår i Rörlighet i staten en enkät med frågor om kännedomen och bilden av Rörlighet i staten.</p><p>Ditt bidrag är viktigt för att vi ska kunna forma och planera våra framtida insatser och kommunikationsstrategier på bästa sätt.</p><p>Har du frågor är du välkommen att höra av dig till <a href="christina.frimodig@naturvardsverket.se">christina.frimodig@naturvardsverket.se</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-03-26T09:20:44",
      title: "Formas söker IT-samordnare för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Formas? De söker just nu en IT-samordnare för inlån under sju månader.</p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-04-10T09:20:44",
      title: "Anmäl dig till kursen Leda utan att vara chef",
      bodyText: {
        content:
          '<p>Vill du stärka din förmåga att skapa engagemang hos dina arbetskamrater, att coacha och driva projekt framåt?</p><p>Utbildningen Leda utan att vara chef stärker din förmåga att möta och hantera de situationer vi kan ställas inför som ledare utan formellt personalansvar. Konkreta verktyg, insikter i ledarrollen och teoretisk kunskap som kan omsättas praktiskt i det dagliga ledarskapet ingår i Leda utan att vara chef. Under utbildningen jobbar vi också med vad det innebär att verka inom staten, vad är en god förvaltningskultur, vad innebär den statliga värdegrunden och hur kan jag utveckla min etiska kompass.</p><p>Deltagare brukar också uppskatta det nätverk av kollegor på andra myndigheter som deltar i utbildningen.</p><p><strong>Utbildningens upplägg</strong></p><p>Varje utbildning tar max 21 deltagare och pågår under fem heldagar och två halvdagar, varav en halvdag är med Statskontoret.</p><p><strong>Målgrupp</strong></p><p>Utbildningens målgrupp är medarbetare som är anställda på någon av myndigheterna inom Rörlighet i staten och som leder andra utan att ha formellt chefsansvar.</p><p><strong>Intresseanmälan</strong>/p><p>Är du intresserad av att delta, stäm av med din chef som i sin tur anmäler dig till myndighetens kontaktperson i Rörlighet i statens programgrupp.</p><p>Vid frågor kontakta Nina Hird, Forte. Nina.hird@forte.se</p><p>Läs mer om utbildningen och rörlighet i staten på: <a href="/program-foer-din-utveckling/leda-utan-att-vara-chef/">Leda utan att vara chef</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-04-16T09:20:44",
      title: "Vinnova söker registrator/administratör för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Vinnova? De söker just nu en registrator/administratör för inlån under sex månader. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-04-30T09:20:44",
      title: "Erfarenhetsutbyte om desinformation – Rörlighet i staten ",
      bodyText: {
        content:
          '<p>Vill du veta mer om desinformation, hur vi som myndigheter kan hantera desinformation och hur andra myndigheter jobbar? Välkommen till Rörlighet i statens digitala popup-samtal den 31 maj kl 9-11.  </p><p><a href="/inspirationsforelasningar-och-samtal/erfarenhetsutbyte-om-desinformation/">Mer info och anmälan</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-03T09:20:44",
      title: "Formas söker analytiker för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Formas under ett halvår. De söker just nu en analytiker för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-03T09:20:44",
      title: "Formas söker kommunikatör för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Formas under ett halvår. De söker just nu en kommunikatör för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-03T09:20:44",
      title:
        "Vill du delta i nätverk för utveckling av digitala verktyg inom staten?",
      bodyText: {
        content:
          '<p>Jobbar du som produktägare/systemägare på någon av myndigheterna inom Rörlighet i staten och vill utbyta erfarenheter med andra? Vi startar ett nytt nätverk kring att bygga digitala produkter i en statlig kontext.</p><p>Att bygga digitala produkter inom staten är speciellt jämfört med den privata sfären. Många av produktägares eller product managers klassiska kärnuppgifter blir mindre relevanta medan andra frågor blir desto viktigare.</p><p>Exempelvis får affärsmässiga frågor som marknadsanalys, produkt-marknads-matchning, intäkter och marknadsföring mindre relevans. Istället blir frågor som att leverera under en fastställd budget, efterleva regelverk, göra det mesta av skattebetalarnas pengar och skapa maximal samhällsnytta viktiga. Vilka faktorer är viktiga för att lyckas bygga en värdefull digital produkt under de särskilda omständigheterna? Hur mäter vi om vi lyckats när vi pratar om samhällsnytta istället för intäkter?</p><p>Nästan alla resurser och utbildningar som handlar om produktägarskap utgår ifrån att man rör sig inom den privata sektorn. Det finns därför en blind spot som ett nätverk inom Rörlighet i Staten skulle kunna fylla. Statliga produktägare skulle kunna utbyta erfarenheter, coacha och tipsa varandra. Vi utvecklar gemensamt hur vi vill jobba i nätverket och vilka frågor vi vill ta upp.</p><p>Är du intresserad av att delta i nätverket, kontakta Andreas Duvhammar, <a href="mailto:Andreas.Duvhammar@tillvaxtverket.se">Andreas.Duvhammar@tillvaxtverket.se</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-03T09:20:44",
      title: "Hög kännedom om Rörlighet i staten",
      bodyText: {
        content:
          '<p>Våren 2024 genomförde IVL Svenska Miljöinstitutet, på uppdrag av Naturvårdsverket och Rörlighet i staten (RIS), en enkätundersökning för att kartlägga medvetenheten och uppfattningen om RIS bland anställda vid de 20 myndigheter som deltar i samarbetet. - Syftet med undersökningen är att ge en grund för framtida utveckling och kommunikationsinsatser inom RIS verksamhet, säger Ingela Hiltula, som är ordförande för verksamheten.</p><p><a href="/hog-kannedom-om-rorlighet i staten/">Läs mer</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-04T09:20:44",
      title: "Boka in höstens inspirationsföreläsningar med Rörlighet i staten",
      bodyText: {
        content:
          '<p><strong>I höst bjuder Rörlighet i staten in till tre digitala föreläsningar för att stärka kompetensen inom viktiga samhälls- och arbetslivsområden. Experter bjuder på insiktsfulla föreläsningar om digitalisering med AI, frågan om ålderism och ledarskap för en hållbar hybrid arbetsplats. Föreläsningarna är för dig som arbetar på en myndighet inom Rörlighet i staten.</strong></p><p>Boka gärna in tiderna i kalendern redan nu. Anmälan kommer ut efter sommaren.</p><p><a href="/inspirationsforelasningar-och-samtal/">Läs mer</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-04T09:20:44",
      title: "Spelinspektionen söker ekonom för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Spelinspektionen? De söker just nu en ekonom för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-04T09:20:44",
      title: "Spelinspektionen söker informationssäkerhetssamordnare för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på att jobba på Spelinspektionen? De söker just nu en informationssäkerhetssamordnare för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-10T09:20:44",
      title: "Energimyndigheten söker handläggare för inlån",
      bodyText: {
        content:
          '<p>Vill du prova på nya arbetsuppgifter under en tid och förstärka teamet som vill förverkliga en satsning på bio-CCS i stor skala? Energimyndigheten söker just nu handläggare till satsningen för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-10T09:20:44",
      title: "Klimatpolitiska rådet söker utredare för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Klimatpolitiska rådet, som är kopplat till Formas. De söker just nu en utredare för inlån.  </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-06-25T09:20:44",
      title: "Formas söker digital verksamhetsutvecklare för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Formas. De söker just nu en digital verksamhetsutvecklare för inlån.  </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-07-01T09:20:44",
      title: "Post- och telestyrelsen söker produkttestare för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Post- och telestyrelsen. De söker just nu en produkttestare för inlån. </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
  {
    Nyhet: {
      createDate: "2024-08-26T09:20:44",
      title: "Vinnova söker en handläggare för inlån",
      bodyText: {
        content:
          '<p>Prova på att jobba på Vinnova. De söker just nu en handläggare till ett regeringsuppdrag kring EU:s gröna giv.  </p><p><a href="/utlaanad-till-annan-myndighet/">Utlånad till annan myndighet</a></p>',
      },
      ingress: {
        content: "",
      },
    },
  },
];
