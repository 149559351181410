function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Mentorskapsprogram för chefer</h1>
      <span className="ingress">
        Mentorskapsprogrammet ger dig möjlighet att utvecklas och förbättras i
        din yrkesroll genom dialog och erfarenhetsutbyte. För såväl mentor som
        adept kan relationen leda till värdefulla insikter både på ett
        professionellt och personligt plan.
      </span>
      <br />
      <br />
      <p className="brdtext">
        Mentor och adept träffas regelbundet under en tidsperiod på ca 11
        månader för dialog och reflektion inom området ledarskap. Adepten är
        styrande när det gäller samtalets innehåll, medan mentorn stöder och
        följer, leder och råder.
      </p>
      <p className="brdtext">
        Under tidsperioden ges också tillfälle att träffa och reflektera
        tillsammans med andra mentorer och adepter i programmet, vid gemensam
        uppstart och ett par gemensamma inspirationsföreläsningar med
        ledarskapstema.
      </p>
      <p className="brdtext">
        Här kan du läsa
        <a
          rel="noopener"
          href="/deltagare-i-aktiviteter-beraettar/"
          target="_blank"
        >
          {" "}
          intervjuer med tidigare deltagare.
        </a>
      </p>
      <h2>Målsättning</h2>
      <ul>
        <li>Personlig utveckling och ökad självkännedom hos deltagarna</li>
        <li>Stärka deltagarna i sitt ledarskap</li>
        <li>Öka självförtroende och initiativkraft hos adepterna</li>
        <li>Utveckla deltagarnas nätverk</li>
        <li>Ett ömsesidigt lärande genom delade tankar och erfarenheter</li>
      </ul>
      <p>&nbsp;</p>
      <h2>Målgrupper</h2>
      <h3>Adepter</h3>
      <p>
        Nya chefer (upp till två års erfarenhet) eller chefer med ett uttalat
        behov att utveckla sitt ledarskap. Som adept ges du vägledning och stöd
        av en mer erfaren person inom din profession.
      </p>
      <h3>Mentorer</h3>
      <p>
        Erfarna chefer eller medarbetare med ledarroll utan personalansvar, med
        en vilja och förmåga att dela med sig av sin kompetens. Som mentor får
        du möjlighet att utvecklas och utmanas i ditt ledarskap och samtidigt få
        nya idéer och influenser från nästa generations ledare.
      </p>
      <p>
        För mer info vänligen se{" "}
        <a
          rel="noopener"
          data-id="3368"
          href="https://rorlighetistaten.se/media/1446/kompetensprofil-foer-mentor-och-adept-2023.pdf"
          target="_blank"
          title="Kompetensprofil För Mentor Och Adept 2023"
        >
          kompetensprofil
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>Tidsperiod&nbsp;</h2>
      <p>
        Programmet startar i januari och pågår cirka 11 månader. Såväl mentorer
        som adepter bör kunna avsätta cirka 40 timmar under perioden för
        programmet och även kunna delta i de gemensamma träffar som
        arrangeras.&nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Kostnad</h2>
      <p>
        Självkostnadspris, cirka 5 000 kronor per adept. Kostnadsfritt för
        mentorer. Var och en står för egna eventuella resekostnader.
      </p>
      <p>&nbsp;</p>
      <h2>Ansökan</h2>
      <p>
        Respektive myndighet föreslår mentorer och adepter till programmet. Är
        du intresserad av att delta som mentor eller adept, prata med din chef
        eller{" "}
        <a rel="noopener" href="/deltagande-myndigheter/" target="_blank">
          kontaktpersonen för Rörlighet i staten på din myndighet
        </a>
        . Anmälan till 2024 ska vara inne senast 17 november 2023.
      </p>
      <p>
        Vid frågor går det även bra att kontakta Petter Svensson,
        fornamn.efternamn@prv.se, som är kontaktperson för den arbetsgrupp som
        ansvarar för mentorskap för ledare.
      </p>
      <p>
        <a
          rel="noopener"
          data-id="3370"
          href="https://rorlighetistaten.se/media/1448/anmaelan-mentor-2023.docx"
          target="_blank"
          title="Anmälan Mentor 2023"
        >
          Anmälningsblankett mentor
        </a>
      </p>
      <p>
        <a
          rel="noopener"
          data-id="3369"
          href="https://rorlighetistaten.se/media/1447/anmaelan-adept-2023.docx"
          target="_blank"
          title="Anmälan Adept 2023"
        >
          Anmälningsblankett adept
        </a>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
