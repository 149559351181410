import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="arkivlista col-sm-10">
      <h1>Utlånad till annan myndighet</h1>
      <span className="ingress">
        Rörlighet i staten ger dig möjlighet att prova på att arbeta på en annan
        myndighet under en begränsad period genom så kallat utlån. Ta chansen
        att prova på en annan arbetsplats, få nya erfarenheter och bredda din
        kompetens.
      </span>
      <br />
      <br />
      <p></p>
      <h2>Ansökan</h2>
      <p>
        Innan du söker ett uppdrag stämmer du av med din chef om möjligheten att
        vara utlånad. Är ni överens skickar du in din ansökan med CV och
        personligt brev på samma sätt som när du söker ett jobb. Motivera varför
        du är intresserad och hur du kan bidra till verksamheten.
      </p>
      <p>
        Via myndigheternas HR-funktioner upprättas en överenskommelse. Utlånet
        varar cirka sex månader. Om något skulle inträffa under utlåningen kan
        överenskommelsen brytas av båda parter med en uppsägningstid på en
        månad.
      </p>
      <p>
        Här kan du läsa{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/">
          erfarenheter från medarbetare som provat på personallån.
        </Link>
      </p>
      <h2>Anställningsform och lön när du är utlånad</h2>
      <p>
        Utlåningen påverkar inte din anställning eller lön. Du är fortsatt
        anställd hos din myndighet som betalar ut din lön som vanligt. Du
        registrerar din arbetstid i ordinarie rapporteringssystem. Myndigheten
        som du är utlånad till ansvarar för arbetsledning och din arbetsmiljö.
      </p>
      <h2>Mer information</h2>
      <p>
        Vill du veta mera om att vara utlånad, kontakta HR-funktionen på din
        myndighet eller vår samordnare Christina Frimodig,
        christina.frimodig@naturvardsverket.se eller 070-673 47 47.
      </p>
      <h2>Aktuella uppdrag</h2>
      <p>
        Här listar vi aktuella förfrågningar från myndigheter som vill låna in
        en person för ett kortare uppdrag. Klicka på respektive rubrik för mer
        info.&nbsp;
      </p>
      <p>
        Finns det ingen förfrågan eller inget som passar dig, ta gärna ett eget
        initiativ och hör av dig till Rörlighet i staten.
      </p>

      <div className="pb-3">
        <h4>
          <a
            href="/media/1501/InlanhandlaggareVinnova2024.pdf"
            title="Vinnova söker en handläggare för inlån"
          >
            Vinnova söker en handläggare för inlån
            <span>&nbsp;</span>
          </a>
        </h4>
        <p>
          <span>
            PProva på att jobba på Vinnova. De söker just nu en handläggare till
            ett regeringsuppdrag kring EU:s gröna giv.{" "}
          </span>
          <a href="/media/1501/InlanhandlaggareVinnova2024.pdf">
            Läs mer om tjänsten här (länk till pdf-fil)
          </a>
        </p>
      </div>

      <div className="pb-3">
        <h4>
          <a
            href="/media/1501/InlanProdukttestarePTS2024.pdf"
            title="Post- och telestyrelsen söker produkttestare för inlån"
          >
            Post- och telestyrelsen söker produkttestare för inlån
            <span>&nbsp;</span>
          </a>
        </h4>
        <p>
          <span>
            Prova på att jobba på Post- och telestyrelsen. De söker just nu en
            produkttestare för inlån.{" "}
          </span>
          <a href="/media/1501/InlanProdukttestarePTS2024.pdf">
            Läs mer om tjänsten här (länk till pdf-fil)
          </a>
        </p>
      </div>

      <div className="pb-3">
        <h4>
          <a
            href="/media/1501/InlanavverksamhetsutvecklareFormas2024New.pdf"
            title="Formas söker digital verksamhetsutvecklare för inlån "
          >
            Formas söker digital verksamhetsutvecklare för inlån
            <span>&nbsp;</span>
          </a>
        </h4>
        <p>
          <span>
            Prova på att jobba på Formas. De söker just nu en digital
            verksamhetsutvecklare för inlån.{" "}
          </span>
          <a href="/media/1501/InlanavverksamhetsutvecklareFormas2024New.pdf">
            Läs mer om tjänsten här (länk till pdf-fil)
          </a>
        </p>
      </div>

      <ul></ul>
    </div>
  );
}
export default Page;
