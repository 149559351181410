import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Vill du delta i nätverk för utveckling av digitala verktyg inom staten?
      </h1>
      <p>
        Jobbar du som produktägare/systemägare på någon av myndigheterna inom
        Rörlighet i staten och vill utbyta erfarenheter med andra? Vi startar
        ett nytt nätverk kring att bygga digitala produkter i en statlig
        kontext.
      </p>
      <p>
        Att bygga digitala produkter inom staten är speciellt jämfört med den
        privata sfären. Många av produktägares eller product managers klassiska
        kärnuppgifter blir mindre relevanta medan andra frågor blir desto
        viktigare.
      </p>
      <p>
        Exempelvis får affärsmässiga frågor som marknadsanalys,
        produkt-marknads-matchning, intäkter och marknadsföring mindre relevans.
        Istället blir frågor som att leverera under en fastställd budget,
        efterleva regelverk, göra det mesta av skattebetalarnas pengar och skapa
        maximal samhällsnytta viktiga. Vilka faktorer är viktiga för att lyckas
        bygga en värdefull digital produkt under de särskilda omständigheterna?
        Hur mäter vi om vi lyckats när vi pratar om samhällsnytta istället för
        intäkter?
      </p>
      <p>
        Nästan alla resurser och utbildningar som handlar om produktägarskap
        utgår ifrån att man rör sig inom den privata sektorn. Det finns därför
        en blind spot som ett nätverk inom Rörlighet i Staten skulle kunna
        fylla. Statliga produktägare skulle kunna utbyta erfarenheter, coacha
        och tipsa varandra. Vi utvecklar gemensamt hur vi vill jobba i nätverket
        och vilka frågor vi vill ta upp.
      </p>
      <p>
        Är du intresserad av att delta i nätverket, kontakta Andreas Duvhammar,{" "}
        <a href="mailto:Andreas.Duvhammar@tillvaxtverket.se">
          Andreas.Duvhammar@tillvaxtverket.se
        </a>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
