function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Leda utan att vara chef</h1>
      <span className="ingress">
        Utbildningen vänder sig till dig som leder projekt, driver
        utvecklingsarbete, leder team eller arbetsgrupper, men som formellt inte
        har en chefsroll.
      </span>
      <br />
      <br />
      <p>
        Utbildningen syftar till att stärka förmågan att möta situationer i
        vardagen kopplade till att leda utan att vara chef. Som deltagare får du
        verktyg för hur du som ledare kan skapa engagemang hos dina kollegor,
        coacha och driva projekt framåt. Målet är att stärka förmågan att som
        ledare klara av olika situationer och samtidigt vara motiverande och
        tydlig i ditt ledarskap. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Upplägg och innehåll</h2>
      <p>
        Innehållet utgörs av bland annat grupputveckling, förändringsledning,
        mötesledning, motivation, självkännedom, tydlighet i ledarrollen,
        kommunikation, feedback, konflikthantering, statliga värdegrunden och
        coachande förhållningssätt. Du kommer också lära dig mer om god
        förvaltningskultur och hur du som statsanställd kan stärka och utveckla
        din etiska kompass när det uppstår dilemman av olika slag.
        Kursdeltagarna bildar också lär-grupper för dialog och
        erfarenhetsutbyte.
      </p>
      <p>
        Utbildningen genomförs både med fysiska träffar centralt i Stockholm och
        digitalt. Fem program/grupper startar våren 2024 och fem startar hösten
        2024. Respektive program tar in max 21 deltagare. &nbsp;
      </p>
      <p>
        Här kan du läsa{" "}
        <a
          rel="noopener"
          href="/deltagare-i-aktiviteter-beraettar/"
          target="_blank"
        >
          intervjuer med tidigare deltagare.
        </a>
      </p>
      <h2>Tidsperiod</h2>
      <p>
        Utbildningen startar med en gemensam digital träff den 4 sept för
        höstens alla grupper. Vid den träffen introduceras programmet och
        tillsammans med Statskontoret diskuteras den statliga värdegrunden som
        ramverk för vårt arbete. Programmet pågår sedan under ytterligare 5,5
        dagar. Som deltagare förväntas du delta på samtliga träffar och även
        utföra hemuppgifter, så{" "}
        <span style={{ textDecoration: "underline" }}>
          kontrollera noga att du kan alla datum och har möjlighet att avsätta
          tid mellan träffarna.
        </span>
      </p>
      <p>
        <strong>Program 54</strong>
        <br />
        Statskontorets pass 4 sep. 9.30-12.00, digitalt
        <br />
        Uppstartsträff 26 aug. kl 13.00-15.00, Zoom
        <br />
        Kurspass 9-10 sep. kl 09.30-17.00 och 09.00-16.30, fysiskt
        <br />
        Kurspass 8-9 okt. kl 08.30-16.00, Zoom
        <br />
        Kurspass 5 nov. kl 09.00-16.00, fysiskt
      </p>
      <p>
        <strong>Program 55</strong>
        <br />
        Statskontorets pass 4 sep. 9.30-12.00, digitalt
        <br />
        Uppstartsträff 5 sep. kl 09.00-11.00, Zoom
        <br />
        Kurspass 24-25 sep. kl 09.30-17.00 och 9.00-16.30, fysiskt
        <br />
        Kurspass 22-23 okt. kl 08.30-16.00, Zoom
        <br />
        Kurspass 25 nov. kl 09.00-16.00, fysiskt
      </p>
      <p>
        <strong>Program 56</strong>
        <br />
        Statskontorets pass 4 sep. 9.30-12.00, digitalt
        <br />
        Uppstartsträff 1 okt. kl 13.00-15.00, Zoom
        <br />
        Kurspass 24-25 okt. kl 09.30-17.00 och 09.00-16.30, fysiskt
        <br />
        Kurspass 21-22 nov. kl 08.30-16.00, Zoom
        <br />
        Kurspass 18 dec. kl 09.00-16.00, fysiskt
      </p>
      <p>
        <strong>Program 57</strong>
        <br />
        Statskontorets pass 4 sep. 9.30-12.00, digitalt
        <br />
        Uppstartsträff 1 okt. kl 10.00-12.00, Zoom
        <br />
        Kurspass 24-25 okt. kl 09.30-17.00 och 09.00-16.30, fysiskt
        <br />
        Kurspass 18-19 nov. kl 08.30-16.00, Zoom
        <br />
        Kurspass 16 dec. kl 09.00-16.00, fysiskt
      </p>
      <br />
      <p>
        Där det anges "fysisk" förväntas du delta på plats i Stockholm, lokal
        meddelas senare. Observera att alla pass är obligatoriska för att
        fortsätta kursen. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Målgrupp</h2>
      <p>
        Medarbetare som har någon form av ledarskap utan att vara formell chef
        och vill eller har behov av att bli tydligare och utvecklas i sin
        ledarroll.
      </p>
      <p>&nbsp;</p>
      <h2>Kostnad</h2>
      <p>
        Självkostnadspris, cirka 14 000 kronor per deltagare. Det finns även
        möjlighet att göra ett individuellt personlighetstest med personlig
        återkoppling av psykologkandidat till en kostnad av 2 500 kr.
      </p>
      <p>
        Kostnad för eventuell resa och boende tillkommer till myndighetens
        bekostnad för dagarna som genomförs i Stockholm. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Anmälan</h2>
      <p>
        Respektive myndighet nominerar deltagare till utbildningen.&nbsp;Är du
        intresserad av att delta, prata med din chef eller{" "}
        <a rel="noopener" href="/deltagande-myndigheter/" target="_blank">
          din myndighets kontaktperson för Rörlighet i staten.
        </a>
      </p>
      <p>Anmälan till hösten 2024 ska vara inne senast 3 maj. &nbsp;</p>
      <p>
        Vid frågor kan du även kontakta Nina Hird, fornamn.efternamn@forte.se,
        som är kontaktperson för den arbetsgrupp inom Rörlighet i staten som
        ansvarar för utbildningen.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
